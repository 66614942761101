// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDuOFp1PVLF-rzERF_i62A7Pd7BhqjdQ5c',
  authDomain: 'todolist-2a7d9.firebaseapp.com',
  databaseURL: 'https://todolist-2a7d9-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'todolist-2a7d9',
  storageBucket: 'todolist-2a7d9.appspot.com',
  messagingSenderId: '61740896543',
  appId: '1:61740896543:web:b06c826a952d8b19e4202a'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };
// Function to handle image upload
