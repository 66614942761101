import { getImageFromFirebase } from '@/DB/getImageFromFirebase';
import { Image } from 'antd';
import { useEffect, useState } from 'react';

interface ImagesContainerProps {
  vacancyId: string;
  isCurrentlyExpanded?: boolean;
}

const ImagesContainer: React.FC<ImagesContainerProps> = ({ vacancyId, isCurrentlyExpanded }) => {
  const [imagesUrls, setImagesUrls] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      console.log(`we call DB fro image`);
      const imagesUrls = await getImageFromFirebase(vacancyId);
      setImagesUrls(imagesUrls);
    };

    fetchData().catch((e) => {
      // handle the error as needed
      console.error('An error occurred while fetching the data: ', e);
    });
  }, []);

  return (
    <div
      style={{
        margin: '2rem 2rem 2rem 0px',
        border: 'AppWorkspace',
        backgroundColor: 'antiquewhite',
        borderStyle: 'dashed',
        display: imagesUrls.length > 0 ? '' : 'none'
      }}
      className="imagesContainer row g-2"
    >
      {imagesUrls &&
        imagesUrls.map((imageUrl, index) => (
          <div
            key={index}
            style={{ width: `${100 / 3}%` }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Image src={`${imageUrl}`} />
          </div>
        ))}
    </div>
  );
};

export default ImagesContainer;
