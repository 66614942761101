export const START_SEARCH_ID_FROM_DB = 1;
export const END_SEARCH_ID_FROM_DB = 13300;
export const MAX_AMMOUNT_OF_IMAGES_UPLOAD = 4;
export const ITEMS_PER_PAGE = 10;
export const DATA_BASE_START_INDEX = 841;
export const MAX_CACHE_LIVE_TIME = 300 * 1000;
export const MIN_PAY = 26;
export const MAX_PAY = 120;

export const ROLLBAR_CONFIG = {
  accessToken: '768acbe99253438588c4f622a4698a68',
  environment: 'testenv'
};
