import { storage } from '@/app/firebase';
import { imageAcessToken } from '@/constants/FierbaseConstants';
import { listAll, ref } from 'firebase/storage';

export async function getImageFromFirebase(id: string) {
  try {
    // // Simulate a delay of 3 seconds
    // await new Promise((resolve) => setTimeout(resolve, 6000));

    // Construct the path dynamically
    const storageRef = ref(storage, `/Workis/${id}`);

    // List all the files in the directory
    const listResult = await listAll(storageRef);
    console.log(`listResult`, listResult);

    // Extract image URLs from listResult
    const imagesUrls = listResult.items.map((item: any) => {
      // Assuming item is a StorageReference object
      const path = item.fullPath; // Get the full path of the item
      const imageUrl = `https://firebasestorage.googleapis.com/v0/b/${
        item.bucket
      }/o/${encodeURIComponent(path)}?alt=media${imageAcessToken}`;
      return imageUrl;
    });

    // Returning the URLs obtained from Firebase
    return imagesUrls;
  } catch (error) {
    console.error('Error fetching images:', error);
    return [];
  }
}
