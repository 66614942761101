import { Vacancy } from '@/types';
import { CaretDownOutlined, CaretUpOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import styles from './TextCollapse.module.scss'; // Assuming you have a SCSS file for styling

const TextCollapse: React.FC<{
  children?: any;
  vacancy: Vacancy;
  isExpanded?: boolean;
  setIsExpanded?: any;
}> = ({ vacancy, isExpanded, children, setIsExpanded }) => {
  const [isOverflown, setIsOverflown] = useState<boolean>(false);

  const [copied, setCopied] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  function isChromeVersionBroken() {
    const ua = navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)
    ) {
      setIsMobile(true);
      return true;
      // const raw = /Chrom(e|ium)\/([0-9]+)\./.exec(ua);

      // return raw ? parseInt(raw[2], 10) >= 71 : false;
    }

    return false;
  }

  useEffect(() => {
    isChromeVersionBroken();
  }, []);

  const extractNumbers = (text: string) => {
    // Regular expression to extract only numbers
    const numbersOnly = text.replace(/\D/g, '');
    return numbersOnly;
  };

  const handleCopy = (contactInfo: string) => {
    const numbers = extractNumbers(contactInfo);
    navigator.clipboard.writeText(numbers);
    setCopied(true);

    // Show Ant Design notification
    notification.success({
      style: { height: '65px' },
      placement: 'top',
      message: t(`COMMON__PHONE_COPIED`),
      duration: 1, // Duration in seconds
      onClose: () => setCopied(false) // Reset the "Copied" state after the notification is closed
    });

    // Reset the "Copied" state after 2 seconds
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflown(contentRef.current.scrollHeight > 200);
    }
  }, [vacancy]);

  // useEffect(() => {
  //   setIsExpanded(!isOverflown);
  // }, [isOverflown]);

  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className={`${styles['content']} ${isExpanded ? `${styles.expanded}` : ''}`}>
        <div ref={contentRef} className={`${styles.inside}`}>
          {vacancy.information}
          {children ? children : null}
        </div>
      </div>

      <div className="actions" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          style={{ backgroundColor: 'white' }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            vacancy.contactInfo && handleCopy(vacancy.contactInfo);
            if (isMobile) {
              // On mobile devices, initiate a phone call
              window.location.href = 'tel:' + vacancy.contactInfo;
            }
          }}
        >
          {vacancy.contactInfo}
          <strong>
            <PhoneOutlined />
          </strong>
        </Button>

        {(Boolean(vacancy.contains_images) || isOverflown) && (
          <Button
            style={{ backgroundColor: `rgb(119 174 255)` }}
            // shape="circle"
            type="primary"
            onClick={(e) => {
              toggleContent();
              e.preventDefault();
            }}
            icon={isExpanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
          >
            {/* {isExpanded ? t('COLLAPSE_TEXT_CONTENT') : t(`EXPAND_TEXT_CONTENT`)} */}
          </Button>
        )}
      </div>
    </>
  );
};

export default TextCollapse;
