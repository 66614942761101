'use client';
import { ITEMS_PER_PAGE } from '@/constants/Constants';
import { Vacancy } from '@/types';
import { Pagination, Spin } from 'antd';
import { usePathname, useRouter } from 'next/navigation';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import VacancyCard from '../VacancyCard/VacancyCard';
// import styles from './ListVacancies.module.scss';

export type ListVacanciesT = {
  VacanciesnFromDB: Vacancy[];
  ammountOfVacancies: any;
  query?: any;
  currentPage?: any;
  currentLocation?: string[];
  isLoading?: boolean;
  setIsLoading?: any;
};

const ListVacancies: React.FC<ListVacanciesT> = ({
  VacanciesnFromDB,
  currentPage,
  currentLocation,
  ammountOfVacancies
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(VacanciesnFromDB ? false : true);
  // const { ammountOfVacancies } = useMyContext();

  console.log(`VacanciesnFromDB in ListVacancies`, VacanciesnFromDB.length);
  console.log(`currentLocation as`, currentLocation);
  console.log(`currentLocation len`, currentLocation?.length && currentLocation[0]);
  useEffect(() => {
    setIsLoading(VacanciesnFromDB ? false : true);
  }, [VacanciesnFromDB]);

  const pageNumber: string | null | number = currentPage;
  const router = useRouter();
  const pathname = usePathname();

  const currentVacancies = VacanciesnFromDB;
  console.log(`currentVacancies`, currentVacancies.length);

  console.log(`pathname in ListVacancies`, pathname);
  const handlePageChange = (page: number) => {
    console.log(`handlePageChange we set page as ${page}`);
    // router.push(`/vacanciesPage?page=${page}`);
    // router.push(`/vacanciesPage?page=${page}`, undefined);
    // router.push(`/vacanciesPage?page=${page}`);
    // setCookie('currentPage', page);
  };

  useEffect(() => {
    console.log(`pageNumber`, pageNumber);
    pageNumber ? handlePageChange(Number(pageNumber)) : '';
  }, [pageNumber]);

  // Define a type guard to check if originalElement is a ReactElement
  const isReactElement = (element: any): element is ReactElement => {
    return React.isValidElement(element);
  };

  const vacanciesContent = useMemo(() => {
    return (
      <>
        <div className={''} style={{ position: 'relative', overflow: 'hidden' }}>
          <div className={''}>
            {false ? (
              // Display a loading spinner while data is being fetched
              <div className={''}>
                <Spin size="large" />
              </div>
            ) : currentVacancies?.length > 0 ? (
              <>
                {currentVacancies.map((vacancy, index) => (
                  <VacancyCard vacancy={vacancy} key={index} />
                ))}
                <Pagination
                  showSizeChanger={false}
                  itemRender={(page, type, originalElement) => {
                    if (type && isReactElement(originalElement)) {
                      return React.cloneElement(originalElement, {
                        key: page,
                        href:
                          currentLocation?.length && currentLocation[0]
                            ? `/vacanciesPage/${page}?location=${currentLocation}`
                            : `/vacanciesPage/${page}`, // for SEO only its not clickable
                        rel: 'dofollow',
                        onClick: (e: any) => {
                          e.preventDefault(); // Prevent default anchor tag behavior
                          router.push(
                            currentLocation?.length && currentLocation[0]
                              ? `/vacanciesPage/${page}?location=${currentLocation}`
                              : `/vacanciesPage/${page}`
                          );
                        }
                      });
                    }
                  }}
                  current={currentPage}
                  pageSize={ITEMS_PER_PAGE}
                  total={ammountOfVacancies}
                />
              </>
            ) : (
              <div className={''} style={{ display: 'flex' }}>
                Not found
              </div>
            )}
          </div>
        </div>
      </>
    );
  }, [pageNumber, VacanciesnFromDB]);

  return (
    <>
      {isLoading ? (
        <>loading</>
      ) : (
        <div className={''}>
          <div className={''}>{vacanciesContent}</div>
        </div>
      )}
    </>
  );
};

export default ListVacancies;
